import { Link, useNavigate } from '@remix-run/react';
import { useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import {
  type DtoOrganizer,
  EnumsEventType,
  EnumsOnboardingTaskName,
  EnumsOnboardingTaskOperation,
  EnumsPageName,
} from '@lp-lib/api-service-client/public';
import { getStaticAssetPath } from '@lp-lib/email-templates/src/utils';

import { useEventSchedulerAnalytics } from '../../../analytics/eventScheduler';
import { apiService } from '../../../services/api-service';
import { fromDTOGamePack } from '../../../utils/api-dto';
import { HostIcon } from '../../icons/HostIcon';
import { PurchaseMethod } from '../../OneTimePurchase/type';
import {
  useMyOrganization,
  useMyOrganizationFeatureChecker,
} from '../../Organization';
import {
  EventScheduleAttendeesField,
  EventScheduleEventTimeField,
  EventScheduleGamePackField,
} from './EventScheduleShared';
import { type EventScheduleLiveFormData } from './types';
import { EventScheduleUtils } from './utils';

function EventScheduled() {
  return (
    <div className='w-full flex justify-center items-center pt-20 text-white'>
      <div className='w-140 bg-modal rounded-2.5xl border border-secondary px-16 py-10 gap-10 flex flex-col items-center'>
        <div className='text-tertiary font-medium text-2xl text-center'>
          Your event request has been submitted
        </div>
        <img
          alt='calendar'
          src={getStaticAssetPath('images/calendar-3d-icon.png')}
          className='w-25 h-25'
        />
        <div className='text-center w-116 text-base'>
          <strong>
            You’ll receive a confirmation of your event within 24 hours.
          </strong>
          <br />
          If your requested slot is not available, we’ll help you pick a new
          date/time.
        </div>

        <Link
          to='/home'
          className='w-96 h-12.5 btn-primary flex items-center justify-center'
        >
          Browse Library
        </Link>
      </div>
    </div>
  );
}

function HostShoutOutField() {
  const { register } = useFormContext<EventScheduleLiveFormData>();

  return (
    <div className='w-full flex gap-4'>
      <div className='mt-1'>
        <HostIcon className='fill-current w-6 h-6' />
      </div>
      <div className='w-full'>
        <div className='text-sms font-bold'>Host Shoutout Customization</div>
        <div className='text-sms text-icon-gray'>
          Anything special you want the host to shoutout?
        </div>

        <div className='mt-5 w-full'>
          <textarea
            {...register('hostShoutOut', {
              maxLength: 1000,
            })}
            className='w-full h-20 field mb-0 px-5 py-4'
            placeholder='We’re celebrating Tim’s birthday!'
            maxLength={1000}
          />
        </div>
      </div>
    </div>
  );
}

export function EventScheduleLive(props: {
  initData: Partial<EventScheduleLiveFormData>;
  recommendedAttendees?: DtoOrganizer[][];
  backTo?: string | null;
}) {
  const { initData, recommendedAttendees, backTo } = props;

  const navigate = useNavigate();
  const analytics = useEventSchedulerAnalytics();
  const organization = useMyOrganization();
  const featureChecker = useMyOrganizationFeatureChecker();

  const form = useForm<EventScheduleLiveFormData>({
    mode: 'onChange',
    defaultValues: initData,
  });
  const { watch, formState } = form;
  const pack = watch('pack');
  const [scheduled, setScheduled] = useState(false);

  const eventType = EnumsEventType.EventTypeLive;
  const hasPermission =
    !pack || featureChecker.canAccessGamePack(fromDTOGamePack(pack));

  const handleSubmit = form.handleSubmit(async (data) => {
    if (!organization || !data.pack || !data.eventTime) return;

    analytics.trackFormSubmitted({
      type: eventType,
      eventType,
      gamePackId: data.pack.id,
      gamePackName: data.pack.name,
      startAt: data.eventTime.startDate.toISOString(),
      timezone: data.eventTime.timezone,
      attendeeUids: data.attendees,
      totalAttendees: data.attendees.length + 1,
      hasHostShoutOut: !!data.hostShoutOut,
      purchaseMethod: organization?.purchase?.gamePackIds.includes(data.pack.id)
        ? PurchaseMethod.OneTimePurchase
        : undefined,
    });

    apiService.onboarding.operateTask(
      EnumsOnboardingTaskName.OnboardingTaskNameBookLiveShow,
      {
        orgId: organization.id,
        operation: EnumsOnboardingTaskOperation.OnboardingTaskOperationMarkDone,
      }
    );

    const attendeeUids = await EventScheduleUtils.AttendeesToUids(
      organization.id,
      data.attendees
    );
    await apiService.event.requestLiveEvent({
      gamePackId: data.pack.id,
      startAt: data.eventTime.startDate.toISOString(),
      timezone: data.eventTime.timezone,
      attendees: attendeeUids,
      hostShoutOut: data.hostShoutOut,
    });

    setScheduled(true);
  });

  const handleCancel = () => {
    if (backTo) {
      navigate(backTo);
    } else {
      navigate(-1);
    }
  };

  if (scheduled) return <EventScheduled />;
  return (
    <FormProvider {...form}>
      <form
        className='
          w-full min-h-full bg-game-library bg-w-full bg-no-repeat bg-top bg-local
          flex text-white 
        '
        onSubmit={handleSubmit}
      >
        <EventScheduleGamePackField
          eventType={eventType}
          pageName={EnumsPageName.PageNameLiveLoggedIn}
          hasPermission={hasPermission}
        />

        <div className='flex-1 max-w-192 p-12.5 flex flex-col gap-10'>
          <div className='flex items-center gap-2 text-3.5xl font-bold'>
            Let's Schedule Your Experience
          </div>
          <div className='flex flex-col gap-7.5'>
            <EventScheduleEventTimeField eventType={eventType} />
            <EventScheduleAttendeesField
              eventType={eventType}
              recommendedAttendees={recommendedAttendees}
            />
            <HostShoutOutField />
          </div>
          <div className='flex justify-end gap-5'>
            <button
              type='button'
              className='btn btn-secondary w-40 h-10'
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              type='submit'
              className='btn-primary w-40 h-10 flex items-center justify-center gap-2'
              disabled={
                !hasPermission || formState.isSubmitting || !formState.isValid
              }
            >
              {form.formState.isSubmitting ? 'Submitting' : 'Submit'}
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
